import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Proof That Your Service is Valued In the Workforce",
  "author": "Anthony",
  "date": "2019-01-02T06:26:52.000Z",
  "categories": ["Diversity and Inclusion"],
  "slug": "proof-that-your-service-is-valued-in-the-workforce",
  "draft": false,
  "meta_title": "Proof That Your Service is Valued In the Workforce"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We spend a lot of time talking about connecting and explaining military experience and credentials in ways the civilian world understands. It’s our passion and vision at `}<a parentName="p" {...{
        "href": "http://www.purepost.co"
      }}>{`Purepost`}</a>{`. It’s who we are and what we do best. But, it’s important not to miss the amazing rise of veteran awareness, outreach, and connectivity outside of the military community. People `}<em parentName="p">{`are`}</em>{` listening. Companies `}<em parentName="p">{`are`}</em>{` creating initiatives to hire and place qualified veterans. Communities `}<em parentName="p">{`are`}</em>{` rallying together to support the veteran population across the board.`}</p>
    <p>{`If you are uncertain as to where and how your service and skills are being valued and doubt the workforce is aware of your transition as a veteran into the civilian community, here are 3 ways we see it happening as we work with corporate America in `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/the-value-of-your-military-experience-in-the-workforce"
      }}>{`placing veterans into civilian positions`}</a>{`.`}</p>
    <p><strong parentName="p">{`Veteran Focused Hiring Departments`}</strong></p>
    <p>{`Human resource and hiring departments throughout corporate America are developing entire teams, programming, and funding focused on veteran hiring initiatives. Company goals and missions are being devised daily with veterans in mind as critical components for success. To see how some of the top companies are doing it best check out the `}<a parentName="p" {...{
        "href": "http://www.diversityjournal.com/15431-25-of-the-most-influential-companies-for-veteran-hiring-2015/"
      }}>{`most influential companies hiring veterans.`}</a></p>
    <p><strong parentName="p">{`Veteran Employment Tax Incentives`}</strong></p>
    <p>{`On December 18, 2015, President Obama signed into law the Protecting Americans from Tax Hikes Act of 2015 (the PATH Act) that extended and modified the Work Opportunity Tax Credit Program and the Empowerment Zones. In summary, it retroactively reauthorized the WOTC program target groups for a five-year period, from December 31, 2014 to December 31, 2019. Unemployed veterans have been identified as one of the target groups umbrelled by this act. To read more, check out the WOTC `}<a parentName="p" {...{
        "href": "https://www.employeebenefitslawreport.com/2015/12/protecting-americans-from-tax-hikes-act-of-2015/"
      }}>{`here.`}</a></p>
    <p><strong parentName="p">{`Veteran Culture Shifts`}</strong></p>
    <p>{`There have been incredible shifts in company cultures to embrace the veteran community. This goes beyond simply filling positions but also encompasses a duty to cultural competency from the core of the organization. They strive to understanding who they are working with, how to speak with them, knowing their language, what they accept and don’t accept, and how they approach someone that has a different set of experiences than maybe most of the company. As a part of this movement, we can assist companies in better understanding the culture of the veteran community and their place in the civilian workforce through our translation services.`}</p>
    <p>{`If you'd like to continue receiving valuable advice on how to utilize your unique military skills in the civilian world, subscribe to our blog! We're here to guide you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      